import React from "react";
import i18n from "../i18n";

const LOADING_TEXT_INTERVAL = 3000;

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      i18n.t("processing_text_1"),
      i18n.t("processing_text_2"),
      i18n.t("processing_text_3"),
      i18n.t("processing_text_4"),
      i18n.t("processing_text_5"),
      i18n.t("processing_text_6"),
      i18n.t("processing_text_7"),
      i18n.t("processing_text_8"),
    ];

    this.state = {
      imageIsLoaded: false,
      textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
    };

    this.updateTextIndex = this.updateTextIndex.bind(this);

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex() {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <main className="loader">
      <div className="spinner-container">
        <div className="triple-spinner">
          <div className="triple-spinner2" />
        </div>
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("loader__title")}} />

        {this.props.image && this.state.imageIsLoaded && <img
          className="avatar-image"
          src={this.props.image}
          alt="" />}
      </div>
      {/*<p dangerouslySetInnerHTML={{__html: this.texts[this.state.textIndex] || ""}} />*/}
    </main>;
  }
}
