module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",
    "btn_retry": "Try again",

    "landing__title": "Your Halloween makeover&nbsp;BOOth!",
    "landing__subtitle": "Are you a creepy creature from a horror movie or a funny Halloween toon?",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Choose photo",

    "processing_text_1": "Eat, drink, be scary! Or cutie?",
    "processing_text_2": "What a BOO-tiful Halloween is coming.",
    "processing_text_3": "We witch you a Happy Halloween!",
    "processing_text_4": "Be a new BOO for our spooky crew 👻",
    "processing_text_5": "May luck be yours on Halloween.",
    "processing_text_6": "Park your broom and join the crowd!",
    "processing_text_7": "Is your pumpkin ready to gleam? 🎃",
    "processing_text_8": "Only best witches for you!",

    "loader_processing": "Please wait…",
    "loader__title": "Creepy<br/>artist",

    "collage__title": "Save and share",
    "collage__text_guest": "This is someone’s Evil Doll, but you could have your own!",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_day": "Day of the Dead",
    "collage__tab_halloween": "Halloween",
    "collage__tab_doll": "Evil Doll",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Download",
    "collage__refresh": "Refresh",
    "collage__back": "Back",
    "collage__next_step": "Next",
    "collage__step_1": "1/2  •  choose body",
    "collage__step_2": "2/2  •  choose background",

    "error__error": "Error",
    "modal_message": "Join our Halloween squad of spookiest & cutest. Share your result on socials!",
    "modal_button": "GOT IT",
    "modal_title": "You look <br/> BOOtiful!",

    "internal_error": "An error occurred...",
    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",
  },
};

