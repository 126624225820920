import React from "react";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import {photolabTask} from "../../photolab/api";
import TemplateItemView from "../../components/TemplateItemView";
import {assetUrl, debounce} from "../../utils/etc";
import Loader from "../../components/Loader";
import i18n from "../../i18n";
import * as api from "../../utils/api";
import {logEvent, userEvents} from "../../utils/log";
import FileChooseButton from "../../components/FileChooseButton";
import ProcessingView from "../../components/ProcessingView";

const views = {
  body: "body",
  background: "background",
};

function createBody(id, splittedPreview) {
  return {id, splittedPreview};
}

const templatesList = [
  createBody(5995, true),
  createBody(5996, true),
  createBody(5997, true),
  createBody(5998, true),
  createBody(5999, true),
  createBody(6000, true),
  createBody(6006, true),
  createBody(6007, true),
  createBody(6008, true),
  createBody(6009, true),
  createBody(6010, true),
  createBody(6059, true),
  createBody(6061, true),
  createBody(6062, true),
  createBody(6063, true),
  createBody(6064, true),
  createBody(6065, false),
  createBody(6066, false),
  createBody(6067, true),
  createBody(6068, false),
  createBody(6069, true),
  createBody(6070, false),
  createBody(6071, false),
  createBody(6072, true),
  createBody(6075, false),
  createBody(6077, false),
  createBody(6081, false),
  createBody(6097, false),
  createBody(6093, false),
  createBody(6094, false),
  createBody(6082, true),
  createBody(6098, true),
  createBody(6095, true),
  createBody(6096, true),
].shuffle();

const backgroundsList = [
  {id: 6011},
  {id: 6012},
  {id: 6013},
  {id: 6014},
  {id: 6015},
  {id: 6016},
  {id: 6017},
  {id: 6018},
  {id: 6019},
  {id: 6047},
  {id: 6048},
  {id: 6049},
  {id: 6050},
  {id: 6051},
  {id: 6052},
  {id: 6053},
  {id: 6054},
  {id: 6055},
].shuffle();

function getTemplatePreviewUrl(template, gender) {
  let fileName = template.id;
  if (template.splittedPreview) {
    if (gender) {
      fileName = template.id + gender[0];
    } else {
      fileName = template.id + "m";
    }
  }

  return assetUrl(`assets/images/halloween/transparentbodies/${fileName}.png`);
}

function getBackgroundImageUrl(id) {
  return assetUrl(`assets/images/halloween/backgrounds/${id}.jpg`);
}

export default class FuuuuuunyTab extends React.Component {

  state = {
    isLoading: false,
    view: views.body,
    templates: templatesList,
    template: templatesList[0],
    background: backgroundsList[0],
    processings: [],
  };

  constructor(props) {
    super(props);

    this.headTaskResult = null;
    this.bodiesTasksResults = [];
    this.templateIsUserSelect = false;
    this.backgroundIsUserSelect = false;

    this.templatesListRef = React.createRef();
    this.backgroundsListRef = React.createRef();
  }

  componentDidMount() {
    this.setListsWheelEvent();
    this.start();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setListsWheelEvent();

    if (!prevProps.gender && this.props.gender) {
      this.start();
    } else if (this.props.file !== prevProps.file) {
      this.reset();
    }
  }

  setListsWheelEvent = () => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.removeEventListener("wheel", this.handleTemplatesListMouseWheel);
      this.templatesListRef.current.addEventListener("wheel", this.handleTemplatesListMouseWheel, {passive: true});
    }

    if (this.backgroundsListRef.current) {
      this.backgroundsListRef.current.removeEventListener("wheel", this.handleBackgroundsListMouseWheel);
      this.backgroundsListRef.current.addEventListener("wheel", this.handleBackgroundsListMouseWheel, {passive: true});
    }
  };

  handleTemplatesListMouseWheel = (e) => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.scrollLeft += e.deltaY;
    }
  };

  handleBackgroundsListMouseWheel = (e) => {
    if (this.backgroundsListRef.current) {
      this.backgroundsListRef.current.scrollLeft += e.deltaY;
    }
  };

  start = () => {
    if (!this.props.gender) {
      return;
    }

    this.handleTemplateSelect(this.state.template);
  };

  reset = () => {
    this.headTaskResult = null;
    this.bodiesTasksResults = [];

    this.setState({processings: []}, this.start);
  };

  handleTemplateSelect = (template, isUserSelect) => {
    this.templateIsUserSelect = !!isUserSelect;

    logEvent(userEvents.TEMPLATE_SELECT, {
      tab: this.props.tabName,
      template_id: template.id,
      template_is_user_select: this.templateIsUserSelect,
      background_id: this.state.background.id,
      background_is_user_select: this.backgroundIsUserSelect,
    });

    this.setState({template}, this.startTemplate);
  };

  handleBackgroundSelect = (background, isUserSelect) => {
    this.backgroundIsUserSelect = !!isUserSelect;

    logEvent(userEvents.TEMPLATE_SELECT_BACKGROUND, {
      tab: this.props.tabName,
      template_id: this.state.template.id,
      template_is_user_select: this.templateIsUserSelect,
      background_id: background.id,
      background_is_user_select: this.backgroundIsUserSelect,
    });

    this.setState({background}, this.startTemplate);
  };

  startTemplate = () => {
    const processing = this.state.processings.find((p) => {
      return p.template.id === this.state.template.id
          && p.background.id === this.state.background.id;
    }) || {};

    if (processing.template) {
      return;
    }

    processing.tries = 0;
    processing.template = this.state.template;
    processing.background = this.state.background;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  startProcessing = (processing) => {
    this.getHeadTask()
      .then((taskResult) => this.getBodyTask(processing.template, taskResult.resultUrl))
      .then((taskResult) => photolabTask(new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(processing.background.id))
        .addImage(new PhotolabTaskImageUrl(taskResult.resultUrl))
        .setLanguage(window.clientConfig.lang || "en")
        .build()))
      .then((taskResult) => api.createTask("creative_store", {
        content_url: taskResult.resultUrl,
        watermark: window.clientConfig.isWebview ? "default_webview_topright" : "creepyartist",
      }))
      .then((taskResult) => api.waitTask(taskResult.id))
      .then((taskResult) => {
        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = {resultUrl: taskResult.result.url};
      })
      .catch((err) => {
        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;
      })
      .then(() => {
        this.setState({processings: this.state.processings.slice()});
      });
  };

  getHeadTask = () => {
    if (this.headTaskResult != null && this.headTaskResult.resultUrl) {
      return Promise.resolve(this.headTaskResult);
    }

    const headConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .addMethod(new PhotolabTaskCollageMethod(5779))
      .build();

    return photolabTask(headConfig, 3000)
      .then((taskResult) => this.headTaskResult = taskResult);
  };

  getBodyTask = (template, headImageUrl) => {
    const task = this.bodiesTasksResults.find((t) => t.template.id === template.id);
    if (task && task.result) {
      return task.result;
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(template.id))
      .addImage(new PhotolabTaskImageUrl(headImageUrl))
      .setLanguage(window.clientConfig.lang || "en")
      .build();

    return photolabTask(taskConfig, 1000)
      .then((taskResult) => {
        this.bodiesTasksResults.push({template, result: taskResult});
        return taskResult;
      });
  };

  handleRetryClick = (processing) => debounce("FuuuuuunnyTab.handleRetryClick", 200, () => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  });

  handleResultLoaded = (processing) => {
    logEvent(userEvents.TEMPLATE_LOADED, {
      tab: this.props.tabName,
      template_id: processing.template.id,
      template_is_user_select: this.templateIsUserSelect,
      background_id: processing.background.id,
      background_is_user_select: this.backgroundIsUserSelect,
    });
  };

  handleDownloadStartClick = (processing) => debounce("FuuuuuunnyTab.handleDownloadStartClick", 200, () => {
    if (this.state.view === views.body) {
      this.setState({view: views.background});
    } else {
      this.props.onDownloadFile(processing.result.resultUrl, {
        tab: this.props.tabName,
        template_id: processing.template.id,
        template_is_user_select: this.templateIsUserSelect,
        background_id: processing.background.id,
        background_is_user_select: this.backgroundIsUserSelect,
      });
    }
  });

  handleUpdateView = (view) => {
    this.setState({view});
  }

  render() {
    if (this.state.isLoading) {
      return <Loader hidden={this.props.hidden} />;
    }

    const processing = this.state.processings.find((p) => {
      return p.template.id === this.state.template.id
          && p.background.id === this.state.background.id;
    });

    return <div className="tab-content" hidden={this.props.hidden}>
      <div className="collage-container">
        <div className="container">
          <h3
            className="step"
            dangerouslySetInnerHTML={{__html: i18n.t(this.state.view === views.body ? "collage__step_1" : "collage__step_2")}}
          />

          <ProcessingView
            processing={processing}
            loaderText={this.props.loaderText}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onRetryClick={() => this.handleRetryClick(processing)}
            onFileSelected={this.props.onFileSelected}
          />
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.body}>
        <div className="container" ref={this.templatesListRef}>
          {this.state.templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            isActive={this.state.template.id === template.id}
            previewUrl={getBackgroundImageUrl(this.state.background.id)}
            onClick={() => this.handleTemplateSelect(template, true)}
            children={<img src={getTemplatePreviewUrl(template, this.props.gender)} alt="Preview" />}
          />)}
        </div>
      </div>

      <div className="templates-container" hidden={this.state.view !== views.background}>
        <div className="container" ref={this.backgroundsListRef}>
          {backgroundsList.map((background) => <TemplateItemView
            key={background.id}
            template={background}
            isActive={this.state.background.id === background.id}
            previewUrl={getBackgroundImageUrl(background.id)}
            onClick={() => this.handleBackgroundSelect(background, true)}
          />)}
        </div>
      </div>

      <div className={"footer-btns" + ((!processing || !processing.isProcessed) ? " invisible" : "")}>
        <button
          className="btn-back"
          hidden={this.state.view !== views.background}
          onClick={() => this.handleUpdateView(views.body)}>
          <div className="btn-back-container">
            <svg viewBox="0 0 32 54">
              <g fill="none" fillRule="evenodd">
                <g fill="#fff">
                  <g>
                    <path d="M31.725 4.725L27 0 0 27 27 54 31.725 49.275 9.085 26.993z" transform="translate(-48 -234) translate(48 234)"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <p dangerouslySetInnerHTML={{__html: i18n.t("collage__back")}} />
        </button>

        <FileChooseButton onFileSelected={this.props.onFileSelected} className="try-photo">
          <div className="try-photo-container">
            <img src={assetUrl(`assets/img/photo.png`)} alt="." />
          </div>
          {i18n.t("try_another_photo")}
        </FileChooseButton>

        <button
          className="btn-done"
          dangerouslySetInnerHTML={{__html: i18n.t(this.state.view === views.body ? "collage__next_step" : "collage__download")}}
          onClick={() => this.handleDownloadStartClick(processing)}
        />
      </div>
    </div>
  }
}