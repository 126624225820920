import React from "react";
import PropTypes from "prop-types";

export default function TemplateItemView({
  template,
  isActive,
  onClick,
  previewUrl,
  children,
  className
}) {
  const classNames = [];

  classNames.push("btn-choice-template");
  classNames.push("btn-choice-template--" + template.id);

  isActive && classNames.push("active");
  className && classNames.push(className);

  return <button
    className={classNames.join(" ")}
    style={{backgroundImage: `url(${previewUrl})`}}
    onClick={() => onClick(template)}
    children={children}
  />;
}

TemplateItemView.propTypes = {
  template: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired
};