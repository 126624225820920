import DumbImageView from "./DumbImageView";
import React from "react";
import PropTypes from "prop-types";
import ProcessingErrorView from "./ProcessingErrorView";
import Loader from "./Loader";

export default class ProcessingView extends React.Component {

  state = {
    isImageLoaded: false,
  };

  handleImageLoaded = () => {
    this.setState({
      isImageLoaded: true,
    }, this.props.onImageLoaded);
  }

  render() {
    const loaderIsHidden = (this.props.processing && this.props.processing.isFailed)
      || (this.props.processing && this.props.processing.isProcessed && this.state.isImageLoaded);

    return <React.Fragment>
      <Loader
        hidden={loaderIsHidden}
        message={this.props.loaderText} />

      {this.props.processing && this.props.processing.isProcessed && <DumbImageView
        className="collage"
        image={this.props.processing.result.resultUrl}
        onImageLoaded={this.handleImageLoaded}
      />}

      {this.props.processing && this.props.processing.isFailed && <ProcessingErrorView
        processing={this.props.processing}
        onRetryClick={this.props.onRetryClick}
        onFileSelected={this.props.onFileSelected}
      />}
    </React.Fragment>;
  }
}

ProcessingView.propTypes = {
  loaderText: PropTypes.string.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onRetryClick: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
}