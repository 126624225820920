import React from "react";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {photolabTask} from "../../photolab/api";
import TemplateItemView from "../../components/TemplateItemView";
import {assetUrl, debounce} from "../../utils/etc";
import Loader from "../../components/Loader";
import i18n from "../../i18n";
import * as api from "../../utils/api";
import {logEvent, userEvents} from "../../utils/log";
import FileChooseButton from "../../components/FileChooseButton";
import ProcessingView from "../../components/ProcessingView";

function createMask(id, name, weights) {
  return {id, name, weights};
}

const templatesList = [
  createMask(5873, "Frankenstein", [10, 10]),
  createMask(5874, "Skellington", [10, 1]),
  createMask(5875, "Goblin", [10, 10]),
  createMask(5876, "Corpse Bride", [1, 10]),
  createMask(5878, "Zombie v2", [10, 10]),
  createMask(5881, "Zombie", [10, 10]),
  createMask(5883, "Scary Nurse", [10, 10]),
  createMask(5884, "Joker", [10, 10]),
  createMask(5885, "Harley Quinn", [1, 10]),
  createMask(5886, "Evil Doll", [1, 1]),
  createMask(5891, "Scary Clown v2", [10, 1]),
  createMask(5892, "Scary Clown", [1, 1]),
  createMask(5893, "Demon", [10, 1]),
  createMask(5894, "Killer Robot", [1, 1]),
  createMask(5895, "Creepy Banshee", [10, 10]),
  createMask(5897, "Chucky", [1, 1]),
  createMask(5898, "Chucky v2", [1, 1]),
  createMask(5900, "Gremlin", [10, 1]),
  createMask(5907, "Gremlin v2", [10, 10]),
  createMask(5908, "Beetlejuice", [1, 1]),
  createMask(5909, "Orc", [1, 1]),
  createMask(5910, "Skull Face", [1, 1]),
  createMask(5912, "Judge Doom", [1, 1]),
  createMask(5913, "Monsters", [1, 1]),
  createMask(5914, "Lord Voldemort v2", [1, 1]),
  createMask(5916, "Boogeyman", [1, 10]),
  createMask(5917, "Dracula", [1, 1]),
  createMask(5921, "Creepy Banshee v2", [10, 10]),
  createMask(5926, "Vampire", [1, 10]),
  createMask(5927, "Werewolf", [10, 1]),
  createMask(5928, "Evil Goblin", [10, 1]),
  createMask(6080, "Evil Doll", [10, 10]),
  createMask(6100, "Pontianak", [10, 10]),
  createMask(6101, "The Evil Within", [10, 10]),
  createMask(6102, "Drunk Doll", [10, 10]),
  createMask(6103, "Oni", [10, 10]),
  createMask(6104, "Drunk Creepy Doll", [10, 10]),
  createMask(6105, "Mysterious Ghost", [10, 10]),
  createMask(6106, "Zombie Doll", [10, 10]),
  createMask(6107, "Creepy Mermaid", [10, 10]),
];

function getTemplatePreviewUrl(template) {
  return assetUrl(`assets/images/halloween/templates/${template.id}.jpeg`);
}

function reorderTemplatesByGenderWeights(templates, gender) {
  const groups = [];

  templates.forEach((template) => {
    const key = template.weights[gender === "male" ? 0 : 1];
    const group = groups.find((g) => g.key === key);
    if (group) {
      group.items.push(template);
    } else {
      groups.push({key, items: [template]});
    }
  });

  groups.sort((a, b) => a.key < b.key ? 1 : -1);

  return groups.flatMap((g) => g.items.shuffle());
}

export default class SpooooookyTab extends React.Component {

  state = {
    isLoading: false,
    templates: [],
    template: null,
    processings: [],
  };

  constructor(props) {
    super(props);
    this.headTaskPromise = null;
    this.cropTaskPromise = null;
    this.templateIsUserSelect = false;
    this.templatesListRef = React.createRef();
  }

  componentDidMount() {
    this.setTemplatesListWheelEvent();
    this.start();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setTemplatesListWheelEvent();

    if (!prevProps.gender && this.props.gender) {
      this.start();
    } else if (this.props.file !== prevProps.file) {
      this.reset();
    }
  }

  setTemplatesListWheelEvent = () => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.removeEventListener("wheel", this.handleTemplatesListMouseWheel);
      this.templatesListRef.current.addEventListener("wheel", this.handleTemplatesListMouseWheel, {passive: true});
    }
  };

  handleTemplatesListMouseWheel = (e) => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.scrollLeft += e.deltaY;
    }
  };

  start = () => {
    if (!this.props.gender) {
      return;
    }

    const nextState = {
      templates: this.state.templates ? this.state.templates : templatesList,
      template: this.state.template ? this.state.template : templatesList.first(),
    };

    if (!this.templateIsUserSelect) {
      nextState.templates = reorderTemplatesByGenderWeights(templatesList, this.props.gender);
      nextState.template = nextState.templates.first();
    }

    this.setState(nextState, () => {
      this.handleTemplateSelect(this.state.template);
    });
  };

  reset = () => {
    this.headTaskPromise = null;
    this.cropTaskPromise = null;

    this.setState({processings: []}, this.start);
  };

  handleTemplateSelect = (template, isUserSelect = false) => {
    this.templateIsUserSelect = !!isUserSelect;

    logEvent(userEvents.TEMPLATE_SELECT, {
      tab: this.props.tabName,
      template_id: template.id,
      template_is_user_select: this.templateIsUserSelect,
    });

    this.setState({template}, this.startTemplate);
  };

  startTemplate = () => {
    const processing = this.state.processings.find((p) => p.template.id === this.state.template.id)
      || {};

    if (processing.template) {
      return;
    }

    processing.tries = 0;
    processing.template = this.state.template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  startProcessing = (processing) => {
    this.getCropTask()
      .then(() => {
        return Promise.all([
          this.getHeadTask(),
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(this.props.file))
            .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
            .setLanguage(window.clientConfig.lang || "en")
            .build())
        ])
      })
      .then(([headTaskResult, templateTaskResult]) => {
        return Promise.all([
          headTaskResult,
          photolabTask(new PhotolabTaskBuilder()
            .addMethod(new PhotolabTaskCollageMethod(6037))
            .addImage(new PhotolabTaskImageUrl(templateTaskResult.resultUrl))
            .build())
        ]);
      })
      .then(([headTaskResult, templateTaskResult]) => {
        return photolabTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod([6056, 6057, 6058].random()))
          .addImage(new PhotolabTaskImageUrl(templateTaskResult.resultUrl))
          .addImage(new PhotolabTaskImageUrl(headTaskResult.resultUrl))
          .build())
      })
      .then((taskResult) => api.createTask("creative_store", {
        content_url: taskResult.resultUrl,
        watermark: window.clientConfig.isWebview ? "default_webview_topright" : "creepyartist",
      }))
      .then((taskResult) => api.waitTask(taskResult.id))
      .then((taskResult) => {
        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = {resultUrl: taskResult.result.url};
      })
      .catch((err) => {
        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;
      })
      .then(() => {
        this.setState({processings: this.state.processings.slice()});
      });
  };

  getCropTask = () => {
    if (this.cropTaskPromise != null) {
      return this.cropTaskPromise;
    }

    const headConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(4021))
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .build();

    return this.cropTaskPromise = photolabTask(headConfig);
  }

  getHeadTask = () => {
    if (this.headTaskPromise != null) {
      return this.headTaskPromise;
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(5779))
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .build();

    return this.headTaskPromise = photolabTask(taskConfig, 3000);
  };

  handleRetryClick = (processing) => debounce("SpooooookyTab.handleRetryClick", 200, () => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  });

  handleResultLoaded = (processing) => {
    logEvent(userEvents.TEMPLATE_LOADED, {
      tab: this.props.tabName,
      template_id: processing.template.id,
      template_is_user_select: this.templateIsUserSelect,
    });
  };

  handleDownloadStartClick = (processing) => debounce("SpooooookyTab.handleDownloadStartClick", 200, () => {
    this.props.onDownloadFile(processing.result.resultUrl, {
      tab: this.props.tabName,
      template_id: processing.template.id,
      template_is_user_select: this.templateIsUserSelect,
    });
  });

  render() {
    if (this.state.isLoading) {
      return <Loader hidden={this.props.hidden} />;
    }

    const processing = this.state.template && this.state.processings
      .find((p) => p.template.id === this.state.template.id);

    return <div className="tab-content" hidden={this.props.hidden}>
      <div className="collage-container">
        <div className="container">
          <ProcessingView
            processing={processing}
            loaderText={this.props.loaderText}
            onImageLoaded={() => this.handleResultLoaded(processing)}
            onRetryClick={() => this.handleRetryClick(processing)}
            onFileSelected={this.props.onFileSelected}
          />
        </div>
      </div>

      <div className="templates-container">
        <div className="container" ref={this.templatesListRef}>
          {this.state.templates.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            className="named"
            isActive={this.state.template ? (this.state.template.id === template.id) : false}
            previewUrl={getTemplatePreviewUrl(template)}
            children={<span dangerouslySetInnerHTML={{__html: template.name.replace(/\sv2$/, "&nbsp;v2")}} />}
            onClick={() => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>

      <div className={"footer-btns" + ((!processing || !processing.isProcessed) ? " invisible" : "")}>
        <FileChooseButton onFileSelected={this.props.onFileSelected} className="try-photo">
          <div className="try-photo-container">
            <img src={assetUrl(`assets/img/photo.png`)} alt="." />
          </div>
          {i18n.t("try_another_photo")}
        </FileChooseButton>
        <button
          className="btn-done"
          dangerouslySetInnerHTML={{__html: i18n.t("collage__download")}}
          onClick={() => this.handleDownloadStartClick(processing)}
        />
      </div>
    </div>
  }
}