import React from "react";
import routes from "../routes";
import Loading from "../components/Loading";
import AppContext from "../contexts/AppContext";
import * as api from "../utils/api";
import {fileToJson} from "../utils/webview";

export default class UploadPage extends React.Component {
  state = {
    isLoading: true,
    fileUrl: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  createFile = (file) => {
    if (window.clientConfig.isWebview) {
      const fileEntity = fileToJson(file);

      this.handleFileUploaded(fileEntity);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        fileUrl: e.target.result
      });
    }

    reader.readAsDataURL(file);

    api.createFile(file)
      .then(this.handleFileUploaded)
      .catch((error) => {
        this.props.history.replace(routes.ERROR, {
          message: error.message,
        });
      });
  };

  handleFileUploaded = (file) => {
    this.props.history.replace({
      pathname: routes.RESULT,
      state: {file}
    });
  }

  render() {
    return <Loading image={this.state.fileUrl} />;
  }
}

UploadPage.contextType = AppContext;